import { format } from 'date-fns';
import { BulkContractFormType, EditPlayerInfoType } from '../../../container/smartlist/type';
import { ContractYearsData, TeamData } from '../../../container/smartlist/aflcontractyears/type';

export const reverseTransformBulkRoasterAllocationData = (
  data: BulkContractFormType,
  teamId: string,
) => {
  return data.playerInfo.map((item) => ({
    player_id: item.playerId,
    contract_type: item.contractType,
    start_date: `${format(new Date(item.startSeason), 'yyyy')}-01-01`,
    end_date: `${format(new Date(item.endSeason), 'yyyy')}-10-31`,
    start_season: format(new Date(item.startSeason), 'yyyy'),
    end_season: format(new Date(item.endSeason), 'yyyy'),
    team: teamId,
    signing_announcement_source: null,
    signing_date: format(new Date(), 'yyyy-MM-dd'),
  }));
};

export const reversePlayerValidate = (data: EditPlayerInfoType, teamId: string) => {
  return {
    player_id: data.playerId,
    start_season: format(new Date(data.startSeason), 'yyyy'),
    end_season: format(new Date(data.endSeason), 'yyyy'),
    team_id: teamId,
  };
};

export const reverseTransformBulkPlayerValidate = (data: EditPlayerInfoType[], teamId: string) => {
  return {
    allocations: data.map((item) => reversePlayerValidate(item, teamId)),
  };
};

export const reverseTransformTeamData = (data: any): ContractYearsData => {
  return {
    teams:
      data?.teams?.map(
        (team: any) =>
          ({
            teamId: team.team_id,
            totalContractYears: team.total_contract_years,
            yearlyBreakdown: team.yearly_breakdown,
            totalNonProjectedYears: team.total_non_projected_years,
            yearlyBreakdownNonProjected: team.yearly_breakdown_non_projected,
          } as TeamData),
      ) || [],
  };
};