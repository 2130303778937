import { PlayerDatabaseType } from '../playerdatabase/type';

export type ContractDetails = {
  contractId: number;
  contractType: string;
  created: string;
  endDate: string;
  signingDate: string;
  startDate: string;
  updated: string;
};

export type PlayerContractDetails = {
  year: string;
  contract: ContractDetails[];
  showLine: boolean;
  addBorderRadius: boolean;
};
export type ClubSummaryPlayerType = {
  playerName: string;
  id: number;
  contractDetails: PlayerContractDetails[];
  playerInfo: PlayerDatabaseType;
};
export type ClubSummaryType = {
  columns: string[];
  playerList: ClubSummaryPlayerType[];
  summaryData: SummaryDataType[];
};

export type SummaryDataType = {
  year: number;
  primaryListOfficial: number;
  primaryListProjected: number;
  rookieListOfficial: number;
  rookieListProjected: number;
  totalListOfficial: number;
  totalListProjected: number;
};

export type CreateContractType = {
  playerId: string;
  contracts: ContractDetails[];
  selectedYear: string;
};

export type EditPlayerInfoType = {
  playerName: string;
  playerId: number;
  startSeason: string;
  endSeason: string;
  contractType: string;
};

export type BulkContractFormType = {
  selectedTeam: string;
  startSeason: string;
  endSeason: string;
  contractType: string;
  selectedPlayerList: number[];
  playerInfo: EditPlayerInfoType[];
};

export type ConflictType = {
  type: string;
  typeDescription: string;
  existingContract: {
    contractType: string;
    endSeason: string;
    id: number;
    startSeason: string;
    team: string;
    teamName: string;
  };
};

export type ValidateConflictType = {
  playerId: number;
  valid: boolean;
  conflicts: ConflictType[];
};

export type BulkValidateConflictType = {
  valid: boolean;
  validationResults: ValidateConflictType[];
};

export enum SMART_LIST_TAB {
  CONTRACTS = 'contracts',
  DEMOGRAPHICS = 'demographics',
  AFL_CONTRACT_YEARS = 'afl_contract_years',
}

export type DemographicFilterType = {
  viewBy: string;
  groupBy: string;
};

export type DemographicDataType = {
  columns: string[];
  rows: string[];
  playerMatrix: Record<string, Record<string, PlayerDatabaseType[]>>;
};