import { format } from 'date-fns';
import {
  AccoladeAddUpdateType,
  ContractAddUpdateType,
  ElegibilityAddUpdateType,
  ExactMatchImportDataType,
  ExactMatchType,
  MatchedPlayerType,
  MergePreviewType,
  PlayeNewPlayeDataType,
  PlayePreviewType,
  PlayerDatabaseType,
  PlayerImportFileType,
  PlayerImportSourceType,
  PlayerManagerAddUpdateType,
  PlayerMatchType,
  PotentialMatchesType,
  PotentialPlayerMatchesType,
} from '../../../container/playerdatabase/type';
import { isEmpty } from 'lodash';

export const reverseTransformPlayerData = (data: PlayerDatabaseType): any => {
  return {
    first_name: data.firstName,
    preferred_first_name: data.preferredFirstName,
    last_name: data.lastName,
    preferred_last_name: data.preferredLastName,
    junior_team: data.juniorTeam,
    date_of_birth: data.dateOfBirth ? format(new Date(data.dateOfBirth), 'yyyy-MM-dd') : '',
    height: data.height,
    weight: data.weight,
    position: data.position,
    secondary_position: data.secondaryPosition,
    state: data.state,
    preferred_foot: data.preferredFoot,
  };
};

export const reversePlayerAccoladeData = (data: AccoladeAddUpdateType): any => {
  return {
    season: data.season,
    accolade_category: data.accoladeCategory,
    accolade_name: data.accoladeName,
  };
};

export const reversePlayerElegibilityData = (data: ElegibilityAddUpdateType): any => {
  return {
    season: data.season,
    eligibility_type: data.elegibilityType,
    eligibility_status: data.elegiblityStatus,
  };
};

export const reversePlayerManagerData = (
  data: PlayerManagerAddUpdateType,
  playerId: string,
): any => {
  return {
    agent_id: data.agentId,
    player_id: playerId,
    start_date: format(new Date(data.startDate), 'yyyy-MM-dd'),
    end_date: format(new Date(data.endDate), 'yyyy-MM-dd'),
  };
};

export const reversePlayerContractData = (data: ContractAddUpdateType): any => {
  return {
    start_date: format(new Date(data.startDate), 'yyyy-MM-dd'),
    end_date: format(new Date(data.endDate), 'yyyy-MM-dd'),
    signing_date: format(new Date(data.signingDate), 'yyyy-MM-dd'),
    team: data.team,
    contract_type: data.contractType,
    start_season: format(new Date(data.startSeason), 'yyyy'),
    end_season: format(new Date(data.endSeason), 'yyyy'),
    signing_announcement_source: data.signingAnnoucementSource
      ? data.signingAnnoucementSource
      : undefined,
    contract_end_reason: data.contractEndReason ? data.contractEndReason : undefined,
  };
};

export const trasnformPlayerImportSource = (data: any): PlayerImportSourceType[] => {
  if (!data) {
    return [];
  }
  return data.sources.map(
    (item: any) =>
      ({
        description: item.description,
        id: item.id,
        name: item.name,
        type: item.type,
      } as PlayerImportSourceType),
  );
};

export const transformPlayerFileData = (data: any): PlayerImportFileType[] => {
  if (!data) {
    return [];
  }
  return data.files.map(
    (item: any, index: number) =>
      ({
        id: index + 1,
        fileName: item.filename,
        lastModified: item.last_modified,
        size: item.size,
      } as PlayerImportFileType),
  );
};

const transformNewPlayersImportData = (data: any): PlayeNewPlayeDataType[] => {
  if (!data) {
    return [];
  }
  return data.map(
    (item: any) =>
      ({
        importData: {
          dataAccessed: item.import_data.data_accessed,
          dateOfBirth: item.import_data.dateOfBirth,
          debutYear: item.import_data.debutYear,
          draftPosition: item.import_data.draftPosition,
          draftType: item.import_data.draftType,
          draftYear: item.import_data.draftYear,
          firstName: item.import_data.firstName,
          heightInCm: item.import_data.heightInCm,
          id: item.import_data.id,
          jumperNumber: item.import_data.jumperNumber,
          position: item.import_data.position,
          providerId: item.import_data.providerId,
          recruitedFrom: item.import_data.recruitedFrom,
          season: item.import_data.season,
          surname: item.import_data.surname,
          team: item.import_data.team,
          weightInKg: item.import_data.weightInKg,
        },
        row: item.row,
        id: item.row,
      } as PlayeNewPlayeDataType),
  );
};

const transformExactMatchImportData = (data: any): ExactMatchImportDataType => {
  if (isEmpty(data)) {
    return {} as ExactMatchImportDataType;
  }
  return {
    dateOfBirth: data.dateOfBirth,
    firstName: data.firstName,
    providerId: data.providerId,
    surname: data.surname,
    heightInCm: data.heightInCm,
    weightInKg: data.weightInKg,
    juniorTeam: data.recruitedFrom,
  };
};

const transformMatchedPlayerType = (data: any): MatchedPlayerType => {
  return {
    playerId: data.player_id,
    name: data.name,
    currentData: {
      dateOfBirth: data.current_data.date_of_birth,
      externalId: data.current_data.external_id,
      firstName: data.current_data.first_name,
      height: data.current_data.height,
      lastName: data.current_data.last_name,
      position: data.current_data.position,
      weight: data.current_data.weight,
      juniorTeam: data.current_data.junior_team,
    },
  };
};
const transformExactMatches = (data: any): ExactMatchType[] => {
  if (!data) {
    return [];
  }
  return data.map(
    (item: any) =>
      ({
        id: item.row,
        row: item.row,
        importData: transformExactMatchImportData(item.import_data),
        match: transformMatchedPlayerType(item.match),
      } as ExactMatchType),
  );
};

const trasnformPotentialPlayerMatches = (data: any): PotentialPlayerMatchesType[] => {
  if (!data) {
    return [];
  }
  return data.map(
    (item: any) =>
      ({
        index: item.player_id,
        playerId: item.player_id,
        name: item.name,
        matchConfidence: item.match_confidence,
        currentData: {
          dateOfBirth: item.current_data.date_of_birth,
          externalId: item.current_data.external_id,
          firstName: item.current_data.first_name,
          height: item.current_data.height,
          lastName: item.current_data.last_name,
          position: item.current_data.position,
          weight: item.current_data.weight,
          juniorTeam: item.current_data.junior_team,
        },
      } as PotentialPlayerMatchesType),
  );
};
const transformPotentialMatches = (data: any): PotentialMatchesType[] => {
  if (!data) {
    return [];
  }
  return data.map(
    (item: any) =>
      ({
        id: item.row,
        row: item.row,
        matches: trasnformPotentialPlayerMatches(item.matches),
        importData: transformExactMatchImportData(item.import_data),
      } as PotentialMatchesType),
  );
};
const transformMatches = (data: any): PlayerMatchType => {
  return {
    exactMatches: transformExactMatches(data.exact_matches),
    newPlayers: transformNewPlayersImportData(data.new_players),
    potentialMatches: transformPotentialMatches(data.potential_matches),
  };
};

export const transformPlayerPreviewData = (data: any): PlayePreviewType | null => {
  if (!data) {
    return null;
  }
  return {
    matches: transformMatches(data.matches),
    summary: {
      exactMatches: data.summary.exact_matches,
      newPlayers: data.summary.new_players,
      potentialMatches: data.summary.potential_matches,
      totalRows: data.summary.total_rows,
    },
  };
};

export const transformMergeSelection = (data: any): MergePreviewType[] => {
  if (!data) {
    return [];
  }
  return data.previews.map(
    (item: any) =>
      ({
        id: `${item.player_id}-${item.row}`,
        playerId: item.player_id,
        row: item.row,
        finalData: {
          dateOfBirth: item.final_data.date_of_birth,
          externalId: item.final_data.external_id,
          firstName: item.final_data.first_name,
          height: item.final_data.height,
          lastName: item.final_data.last_name,
          position: item.final_data.position,
          weight: item.final_data.weight,
          juniorTeam: item.final_data.junior_team,
        },
      } as MergePreviewType),
  );
};