import React from 'react';
import {
  Box,
  Switch,
  FormControlLabel,
  Typography,
  Paper,
  Grid,
  Stack,
  useTheme,
  alpha,
} from '@mui/material';
import ContractYearsChart from './ContractYearsChart';
import { useAflContractYears } from '../hook';

const AflContractYears: React.FC = () => {
  const theme = useTheme();
  const {
    data,
    isLoading,
    setShowDetailedView,
    setShowNonProjected,
    showDetailedView,
    showNonProjected,
  } = useAflContractYears();

  if (isLoading) {
    return (
      <Paper
        elevation={3}
        sx={{
          p: 3,
          m: 2,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: 300,
        }}
      >
        <Typography
          variant='h6'
          color='text.secondary'
          sx={{
            fontWeight: 500,
            letterSpacing: 1,
          }}
        >
          Loading Contract Data...
        </Typography>
      </Paper>
    );
  }

  if (!data?.teams) {
    return (
      <Paper
        elevation={3}
        sx={{
          p: 3,
          m: 2,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: 300,
        }}
      >
        <Typography
          variant='h6'
          color='text.secondary'
          sx={{
            fontWeight: 500,
            letterSpacing: 1,
          }}
        >
          No Teams found
        </Typography>
      </Paper>
    );
  }
  return (
    <Paper
      elevation={6}
      sx={{
        p: 3,
        m: 2,
        borderRadius: 3,
        background: `linear-gradient(135deg, ${alpha(
          theme.palette.background.default,
          0.6,
        )}, ${alpha(theme.palette.background.paper, 0.9)})`,
        boxShadow: `0 12px 24px ${alpha(theme.palette.primary.main, 0.1)}`,
      }}
    >
      <Grid container spacing={2} alignItems='center' sx={{ mb: 3 }}>
        <Grid item xs={12} md={6}>
          <Typography
            variant='h5'
            sx={{
              fontWeight: 600,
              color: theme.palette.text.primary,
              letterSpacing: 0.5,
            }}
          >
            Team Contract Years Distribution
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Stack direction='row' spacing={2} justifyContent='flex-end' alignItems='center'>
            <FormControlLabel
              control={
                <Switch
                  checked={showNonProjected}
                  onChange={(e) => setShowNonProjected(e.target.checked)}
                  color='primary'
                />
              }
              label={
                <Typography variant='body2' color='text.secondary' sx={{ fontWeight: 500 }}>
                  Non-Projected
                </Typography>
              }
            />
            <FormControlLabel
              control={
                <Switch
                  checked={showDetailedView}
                  onChange={(e) => setShowDetailedView(e.target.checked)}
                  color='secondary'
                />
              }
              label={
                <Typography variant='body2' color='text.secondary' sx={{ fontWeight: 500 }}>
                  Detailed View
                </Typography>
              }
            />
          </Stack>
        </Grid>
      </Grid>

      <Box
        sx={{
          borderRadius: 3,
          overflow: 'hidden',
          boxShadow: `0 8px 16px ${alpha(theme.palette.primary.main, 0.05)}`,
          border: `1px solid ${alpha(theme.palette.divider, 0.1)}`,
        }}
      >
        <ContractYearsChart
          showDetailedView={showDetailedView}
          teams={data.teams}
          showNonProjected={showNonProjected}
        />
      </Box>
    </Paper>
  );
};

export default AflContractYears;