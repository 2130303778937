import Styled from '@emotion/styled';
import Typography from '@mui/material/Typography';
import { TEXT_COLOR_THEME } from '../../theme/color';
import { FONT_THEME } from '../../theme/fontTheme';

type TextProps = {
  textsize?: string;
  fontcolor?: string;
  fontweight?: number | string;
  wraptext?: number;
  ellipses?: number;
};

export const ErrorText = Styled(Typography)<TextProps>`
    font-family: ${FONT_THEME.secondary};
    font-size: ${(props) => props.textsize ?? '12px'};
    color: ${TEXT_COLOR_THEME.error};
`;

export const Header1 = Styled(Typography)`
  font-family: ${FONT_THEME.primary};
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 27px;
  letter-spacing: -0.01em;
  color: ${TEXT_COLOR_THEME.dark};
`;

export const Header2 = Styled(Typography)<TextProps>`
  font-family: ${FONT_THEME.primary};
  font-style: normal;
  font-weight: ${(props) => props.fontweight ?? 500};
  font-size: ${(props) => props.textsize ?? '16px'};
  line-height: 27px;
  letter-spacing: -0.01em;
  color: ${(props) => props.fontcolor ?? TEXT_COLOR_THEME.dark};
  ${(props) => props.ellipses && 'white-space: nowrap; overflow: hidden; text-overflow: ellipsis;'}
`;

export const Header3 = Styled(Typography)<TextProps>`
  font-family: ${FONT_THEME.primary};
  font-style: normal;
  font-weight: 700;
  font-size: ${(props) => props.textsize ?? '16px'};
  line-height: 27px;
  letter-spacing: -0.01em;
  color: ${(props) => props.fontcolor ?? TEXT_COLOR_THEME.dark};
`;

type TagProps = {
  bgcolor: string;
  textsize?: string;
  fontcolor?: string;
};
export const Tag = Styled(Typography)<TagProps>`
  padding: 0px 10px;
  border-radius: 5px;
  background-color: ${(props) => props.bgcolor};
  font-family: ${FONT_THEME.primary};
  font-style: normal;
  font-weight: 400;
  font-size: ${(props) => props.textsize ?? '16px'};
  line-height: 27px;
  letter-spacing: -0.01em;
  color: ${(props) => props.fontcolor ?? TEXT_COLOR_THEME.light};
`;
type CompactTagProps = {
  bgcolor: string;
  textsize?: string;
  fontcolor?: string;
  width?: number;
  minWidth?: number;
};
export const CompactTag = Styled(Typography)<CompactTagProps>`
  padding: 0px 5px;
  border-radius: ${(props) => `${props.width || props.minWidth}px` || '15px'};
  border: 1px solid ${(props) => props.fontcolor ?? TEXT_COLOR_THEME.light};
  background-color: ${(props) => props.bgcolor};
  width: ${(props) => `${props.width}px` || 'fit-content'};
  min-width: ${(props) => `${props.minWidth}px` || ''};
  font-family: ${FONT_THEME.primary};
  font-style: normal;
  font-weight: 400;
  font-size: ${(props) => props.textsize ?? '16px'};
  line-height: 27px;
  letter-spacing: -0.01em;
  color: ${(props) => props.fontcolor ?? TEXT_COLOR_THEME.light};
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const FieldLabel = Styled(Typography)`
  font-family: ${FONT_THEME.secondary};
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5;
`;

export const ColumnHeaderText = Styled(Typography)`
    color: #27303F;
    font-size: 12px;
    font-weight: 600!important;
    text-transform: uppercase;
    letter-spacing: -0.01em;
    overflow: visible;
    text-overflow: unset;
    font-family: 'Poppins',sans-serif;
`;
