import { Grid } from '@mui/material';
import { useSmartList } from './hook';
import CustomDropdown from '../../common/fields/CustomDropdown';
import AddUpdatePlayer from '../playerdatabase/component/AddUpdatePlayer';
import { PlayerSelectedTabEnum } from '../playerdatabase/type';
import AddTimelineNote from './component/AddTimelineNote';
import AddUpdateContract from '../playerdatabase/component/AddUpdateContract';
import SmartPlayer from './component/SmartPlayer';
import BulkContractModal from './component/BulkContractModal';
import CustomTab from '../../common/component/CustomTab';
import { smartListTabOption } from './util';
import { SMART_LIST_TAB } from './type';
import Demographic from './Demographic';
import AflContractYears from './aflcontractyears/AflContractYears';

const SmartList = () => {
  const {
    selectedTeam,
    selectedPlayerForEdit,
    playerListInfo,
    setSelectedPlayerForEdit,
    setSelectedTeam,
    playerListOption,
    smartListData,
    isLoading,
    refreshData,
    teamOptions,
    selectedPlayerForAddNote,
    setSelectedPlayerForAddNote,
    addContract,
    setHandleAddContract,
    openBulkContractModal,
    setOpenBulkContractModal,
    selectedViewTab,
    setSelectedViewTab,
  } = useSmartList();

  const renderView = () => {
    if (selectedViewTab === SMART_LIST_TAB.CONTRACTS) {
      return (
        <SmartPlayer
          summaryList={smartListData.summaryData}
          onEditClick={(playerId) => {
            setSelectedPlayerForEdit(
              playerListInfo.find((item) => item.id.toString() === playerId.toString()),
            );
          }}
          setOpenBulkContractModal={setOpenBulkContractModal}
          loading={isLoading}
          playerListOption={playerListOption}
          onAddNoteClick={(playerId) => {
            setSelectedPlayerForAddNote(
              playerListInfo.find((item) => item.id.toString() === playerId.toString()),
            );
          }}
          onYearAddContractClick={(contractInfo) => {
            setHandleAddContract(contractInfo);
          }}
          playerList={smartListData.playerList}
          years={smartListData.columns}
        />
      );
    }
    if (selectedViewTab === SMART_LIST_TAB.DEMOGRAPHICS) {
      return <Demographic playerList={smartListData.playerList.map((item) => item.playerInfo)} />;
    }
    return <AflContractYears />;
  };
  return (
    <Grid container mt={'20px'}>
      <Grid item xs={12} mb={'10px'}>
        <Grid container justifyContent={'space-between'}>
          <Grid item xs={12} md={4}>
            <CustomDropdown
              label='Team'
              options={teamOptions}
              fullWidth
              variant='outlined'
              onOptionChange={setSelectedTeam}
              value={selectedTeam}
            />
          </Grid>
          <Grid item xs={12} md={7}>
            <CustomTab
              tabOption={smartListTabOption}
              value={selectedViewTab}
              setTabSelected={(value) => setSelectedViewTab(value as SMART_LIST_TAB)}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        {renderView()}
      </Grid>
      {selectedPlayerForEdit && (
        <AddUpdatePlayer
          refreshList={refreshData}
          handleClose={() => {
            setSelectedPlayerForEdit(undefined);
            refreshData();
          }}
          initialTab={PlayerSelectedTabEnum.CONTRACT}
          playerData={selectedPlayerForEdit}
          selectedTeam={selectedTeam}
        />
      )}
      {selectedPlayerForAddNote && (
        <AddTimelineNote
          playerId={selectedPlayerForAddNote.id}
          handleClose={() => {
            setSelectedPlayerForAddNote(undefined);
          }}
        />
      )}
      {addContract && (
        <AddUpdateContract
          handleClose={() => {
            setHandleAddContract(undefined);
          }}
          playerId={addContract.playerId}
          startSeason={addContract.selectedYear}
          refreshList={() => {
            setHandleAddContract(undefined);
            refreshData();
          }}
          seasonList={addContract.contracts.map((item) => ({
            endSeason: new Date(item.endDate).getFullYear().toString(),
            startSeason: new Date(item.startDate).getFullYear().toString(),
          }))}
          selectedTeam={selectedTeam}
        />
      )}

      {openBulkContractModal && (
        <BulkContractModal
          teamOptions={teamOptions}
          selectedTeamName={selectedTeam}
          playerListOption={playerListOption}
          playerListInfo={playerListInfo}
          handleClose={() => setOpenBulkContractModal(false)}
          handleRefreshList={() => {
            refreshData();
            setOpenBulkContractModal(false);
          }}
        />
      )}
    </Grid>
  );
};

export default SmartList;