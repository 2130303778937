import { Box, Grid, IconButton, Tooltip } from '@mui/material';
import { ReactNode } from 'react';
import { PlayerRankingType } from '../type';
import { Draggable } from 'react-beautiful-dnd';
import { Header2, Header3, Tag } from '../../../common/component/Text';
import { draftPlayerPositionObj } from '../../../constant/OptionConstant';
import { BG_COLOR, TEXT_COLOR_INFO_TYPE, TEXT_COLOR_THEME } from '../../../theme/color';
import useWindowSize from '../../../common/hooks';
import { IoMdTrash } from 'react-icons/io';

type DraggableRankDataGridProps = {
  tiers: PlayerRankingType[];
  providerPlaceholder: ReactNode;
  handleDeletePlayer: (playerId: string) => void;
};
const DraggableRankDataGrid = ({
  tiers,
  handleDeletePlayer,
  providerPlaceholder,
}: DraggableRankDataGridProps) => {
  const { windowHeight } = useWindowSize();
  const renderComponent = (item: PlayerRankingType) => {
    return (
      <>
        <Box width={'20%'}>
          <Tag textAlign={'center'} width={'20px'} bgcolor={TEXT_COLOR_INFO_TYPE.neutral}>
            {item.rank}
          </Tag>
        </Box>
        <Box width={'70%'}>
          <Header2 textsize='12px' style={{ lineHeight: '10px' }} fontweight={700}>
            {item?.fullName || item.firstName}
          </Header2>
          <Tooltip title={item.club}>
            <Header3
              style={{
                fontWeight: 500,
                lineHeight: 'normal',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: '-webkit-box',
                WebkitLineClamp: '1',
                WebkitBoxOrient: 'vertical',
              }}
              textsize={'12px'}
            >
              {item?.club || item?.state}
            </Header3>
          </Tooltip>
        </Box>
        <Box width={'20%'}>
          <Tooltip placement='right-start' title={item?.position1 || (item as any)?.position}>
            <Tag
              textAlign={'center'}
              width={'20px'}
              textsize='12px'
              height={'fit-content'}
              bgcolor={
                draftPlayerPositionObj?.[item?.position1?.toLowerCase() || (item as any)?.position]
                  ?.color || ''
              }
            >
              {draftPlayerPositionObj?.[item?.position1?.toLowerCase() || (item as any)?.position]
                ?.text || item.position1}
            </Tag>
          </Tooltip>
        </Box>
        <Box width={'5%'} mr={'5px'}>
          <IconButton
            onClick={() => {
              handleDeletePlayer(item.id.toString());
            }}
            style={{ padding: 0 }}
          >
            <IoMdTrash color={TEXT_COLOR_THEME.primary} />
          </IconButton>
        </Box>
      </>
    );
  };
  return (
    <Grid container>
      <Grid item xs={12}>
        <Box overflow={'auto'} height={`${windowHeight - 220}px`}>
          {tiers.map((player, index) => (
            <Draggable draggableId={player.id.toString()} index={index} key={player.id}>
              {({ dragHandleProps, draggableProps, innerRef }) => (
                <Box
                  bgcolor={BG_COLOR.lightDark}
                  ref={innerRef}
                  border={'1px #efefef solid'}
                  height={`${(windowHeight - 500) / tiers.length - 1}px`}
                  key={index}
                  maxHeight={'18px'}
                  display={'flex'}
                  gap={'5px'}
                  padding={'10px'}
                  alignItems={'center'}
                  {...draggableProps}
                  {...dragHandleProps}
                >
                  {renderComponent(player)}
                </Box>
              )}
            </Draggable>
          ))}
          {providerPlaceholder}
        </Box>
      </Grid>
    </Grid>
  );
};

export default DraggableRankDataGrid;
