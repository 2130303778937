import Styled from '@emotion/styled';
import Button from '@mui/material/Button';
import { BUTTON_COLOR_THEME, DATAGRID_COLOR } from '../../theme/color';
import { FONT_THEME } from '../../theme/fontTheme';
import { Box, Divider, Grid, StepConnector, stepConnectorClasses, styled } from '@mui/material';
import { TEXT_COLOR_THEME } from '../../theme/color';

export const StyledButton = Styled(Button)`
    background: ${BUTTON_COLOR_THEME.primary};
    color: ${BUTTON_COLOR_THEME.light};
    font-family: ${FONT_THEME.primary};
    border-radius: 20px;
    padding: 10px;
    width: 100%;
    :disabled {
        color: ${BUTTON_COLOR_THEME.light};
        opacity: 0.5;
    }
    :hover {
        background: ${BUTTON_COLOR_THEME.primary};
        opacity: 0.8;
    }
`;

export const StyledOutlineButton = Styled(Button)`
    background: ${BUTTON_COLOR_THEME.light};
    color: ${BUTTON_COLOR_THEME.primary};
    font-family: ${FONT_THEME.primary};
    border-radius: 20px;
    padding: 9px;
    border: 1px ${BUTTON_COLOR_THEME.primary} solid;
    width: 100%;
    :disabled {
        color: ${BUTTON_COLOR_THEME.primary};
        opacity: 0.5;
    }
    :hover {
        background: ${BUTTON_COLOR_THEME.light};
        opacity: 0.8;
    }
`;

export const StyledTextButton = Styled(Button)`
    color: ${BUTTON_COLOR_THEME.primary};
    font-family: ${FONT_THEME.primary};
    border-radius: 20px;
    width: 100%;
    padding: 10px;
    :hover {
        opacity: 0.8;
    }
`;

type OptionsProps = {
  buttontype?: 'text';
};
export const StyledOptionButton = Styled(Button)<OptionsProps>`
    background: ${(props) =>
      props.buttontype === 'text' ? BUTTON_COLOR_THEME.light : BUTTON_COLOR_THEME.primary};
    color: ${(props) =>
      props.buttontype === 'text' ? BUTTON_COLOR_THEME.primary : BUTTON_COLOR_THEME.light};
    font-family: ${FONT_THEME.primary};
    border-radius: 5px;
    padding: 10px;
    ${(props) =>
      props.buttontype === 'text' &&
      `
        border: 1px ${BUTTON_COLOR_THEME.primary} solid;
    `};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    :hover {
        background: ${(props) =>
          props.buttontype === 'text' ? BUTTON_COLOR_THEME.light : BUTTON_COLOR_THEME.primary};
        opacity: 0.8;
    }
`;

type StyledDataGridProps = {
  nodataheight?: string;
  nopadding?: number;
};
export const StyledDataGrid = Styled(Grid)<StyledDataGridProps>`
& .MuiDataGrid-columnSeparator {
    display: none;
};

& .MuiDataGrid-root {
    border-radius: 13px;
};

& .MuiDataGrid-virtualScrollerContent {
    height: ${(props) => props.nodataheight || undefined} !important;
}

& .MuiDataGrid-columnHeader {
    font-size: 12px;
    outline: none !important;
    background-color: ${DATAGRID_COLOR.headerBackground};
};

& .MuiDataGrid-cell {
    font-size: 12px;
    outline: none !important;
    ${(props) =>
      props.nopadding &&
      `
        padding: 0px !important;
        border-bottom: 0px;
    `};
};

& .MuiDataGrid-columnHeaderTitle {
    color: ${DATAGRID_COLOR.headerColor};
          font-weight: 600 !important;
          text-transform: uppercase;
          letter-spacing: -0.01em;
          overflow: visible;
          text-overflow: unset;
          font-family: ${FONT_THEME.primary};
};

& .MuiDataGrid-columnsContainer {
    border-bottom: none !important;
          border-radius: 12;
          top: 4px;
};

& .MuiDataGrid-row {
    width: unset;
    outline: none !important;
}

& .MuiDataGrid-row:hover {
    background-color: ${DATAGRID_COLOR.headerBackground};
    border-radius: 0px;
}

& .MuiDataGrid-main {
    background-color: white;
          border-radius: 10px;
}

& .MuiDataGrid-window {
    top: 62px !important;
}
& .MuiDataGrid-columnHeaderTitleContainer {
    padding: 0;
}
`;

export const StyledTabContainer = Styled(Box)`
 & .MuiButtonBase-root {
    font-family: ${FONT_THEME.secondary};
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
 };
 
 & .Mui-selected {
    color: ${BUTTON_COLOR_THEME.primary} !important;
    border-radius: 10px 0px 0px 10px;
 }

 & .MuiTabs-indicator {
    background-color: ${BUTTON_COLOR_THEME.primary};
 }

 & .MuiButtonBase-root {
    align-items: baseline;
 }
`;

type StyledGridColumnProps = {
  bgcolor: string;
};
export const StyledGridColumn = Styled.div<StyledGridColumnProps>`
    background: ${(props) => props.bgcolor};
    width: 100%;
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
`;

export const HighlightedDivider = Styled(Divider)`
    border: 2px solid ${TEXT_COLOR_THEME.dark};
    position: absolute;
    left: 0px;
    margin-top: 10px;
    right: 0px;
`;

export const ColorlibStepIconRoot = styled('div')<{
  ownerState: { completed?: boolean; active?: boolean };
}>(({ ownerState }) => ({
  backgroundColor: '#ccc',
  zIndex: 1,
  color: '#fff',
  width: 50,
  height: 50,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  ...(ownerState.active && {
    backgroundImage:
      'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  }),
  ...(ownerState.completed && {
    backgroundImage:
      'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
  }),
}));

export const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor: '#eaeaf0',
    borderRadius: 1,
  },
}));
