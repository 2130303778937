/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState } from 'react';
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';
import { Box, Grid } from '@mui/material';
import { PlayerDatabaseType } from '../../playerdatabase/type';
import { PlayerRankingType, TiersState, UpdateRankingType } from '../type';
import { StyledCard } from '../../../common/component/Card';
import DragPlayerList from './DragPlayerList';
import DraggableRankDataGrid from './DraggableRankDataGrid';
import { Header3 } from '../../../common/component/Text';
import DeleteModal from '../../../common/component/DeleteModal';

type Props = {
  rank: PlayerRankingType[];
  playerList: PlayerDatabaseType[];
  updatePlayerPos: (playerInfo: UpdateRankingType) => void;
  isLoading: boolean;
  handleDeletePlayer: (playerId: string) => void;
};

const DragAndDropRanking = ({
  updatePlayerPos,
  handleDeletePlayer,
  rank,
  playerList,
  isLoading,
}: Props) => {
  const [tiers, setTiers] = useState<TiersState>({
    tier1: rank.filter((player) => player.tier === 1),
    tier2: rank.filter((player) => player.tier === 2),
    tier3: rank.filter((player) => player.tier === 3),
    tier4: rank.filter((player) => player.tier === 4),
    tier5: rank.filter((player) => player.tier === 5),
    playerList: playerList,
  });
  const [deleteConfirmModal, setDeleteConfirmModal] = useState<string | null>(null);
  const [deleteLoading, setDeleteLoading] = useState(false);

  const onDragEnd = (result: DropResult) => {
    const { source, destination } = result;
    if (!destination || destination.droppableId === 'playerList') return;

    const sourceTier = source.droppableId as keyof TiersState;
    const destTier = destination.droppableId as keyof TiersState;

    // First, ensure all players have unique IDs across all tiers
    const allPlayersById: Record<string, PlayerRankingType> = {};
    Object.keys(tiers).forEach((tierKey) => {
      const tierList = tiers[tierKey as keyof TiersState] as PlayerRankingType[];
      tierList.forEach((player) => {
        const playerId = player.id.toString();
        allPlayersById[playerId] = player;
      });
    });

    // Create fresh arrays for all tiers based on unique players
    const freshTiers: TiersState = Object.keys(tiers).reduce((acc, tierKey) => {
      acc[tierKey as keyof TiersState] = [];
      return acc;
    }, {} as TiersState);

    // Re-populate tiers with unique players
    Object.keys(tiers).forEach((tierKey) => {
      const tierList = tiers[tierKey as keyof TiersState] as PlayerRankingType[];
      const uniquePlayers = tierList.filter((player, index) => {
        const playerIdStr = player.id.toString();
        return tierList.findIndex((p) => p.id.toString() === playerIdStr) === index;
      });
      freshTiers[tierKey as keyof TiersState] = uniquePlayers as any;
    });

    // Clone the source and destination lists from our fresh state
    const sourceList = Array.from(freshTiers[sourceTier] as PlayerRankingType[]);
    const destList = Array.from(freshTiers[destTier] as PlayerRankingType[]);

    // Remove the player from the source tier
    const [movedPlayer] = sourceList.splice(source.index, 1);

    // Remove any instances of this player from destination tier too (to prevent duplicates)
    const playerId = movedPlayer.id.toString();
    const destIndex = destList.findIndex((p) => p.id.toString() === playerId);
    if (destIndex !== -1) {
      destList.splice(destIndex, 1);
    }

    // Calculate the new tier
    const newTier = parseInt(destTier.replace('tier', ''));

    // Add the player to the destination tier with updated tier
    const updatedPlayer = { ...movedPlayer, tier: newTier };
    destList.splice(destination.index, 0, updatedPlayer);

    // Recalculate ranks for all players
    const updatedTiers = { ...freshTiers, [sourceTier]: sourceList, [destTier]: destList };

    // Get all tier keys and sort them numerically
    const tierKeys = Object.keys(updatedTiers)
      .filter((key) => key.startsWith('tier'))
      .sort((a, b) => parseInt(a.replace('tier', '')) - parseInt(b.replace('tier', '')));

    // Assign sequential ranks to all players across tiers
    let currentRank = 1;

    tierKeys.forEach((tierKey) => {
      const tierPlayers = updatedTiers[tierKey as keyof TiersState] as PlayerRankingType[];

      tierPlayers.forEach((player, index) => {
        (updatedTiers[tierKey as keyof TiersState] as PlayerRankingType[])[index].rank =
          currentRank;
        currentRank++;
      });
    });
    setTiers(updatedTiers);
    updatePlayerPos({
      ranking: (updatedTiers[destTier][destination.index] as any).rank,
      tier: newTier,
      playerId: playerId,
    });
  };
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Grid container columnSpacing={'20px'}>
        <Grid item xs={9}>
          <Grid container style={{ pointerEvents: isLoading ? 'none' : 'all' }}>
            <Grid item xs={12}>
              <Grid overflow={'auto'} gap={'30px'} display={'flex'}>
                {Object.keys(tiers)
                  .filter((key) => key !== 'playerList')
                  .map((tierKey, idx) => (
                    <Droppable droppableId={tierKey} key={tierKey}>
                      {(provided) => (
                        <StyledCard>
                          <Box ref={provided.innerRef} {...provided.droppableProps} width={270}>
                            <Grid width={'100%'}>
                              <Header3 textsize={'16px'} textAlign={'center'}>
                                {`Tier ${idx + 1}`}
                              </Header3>
                            </Grid>
                            <Grid item xs={12}>
                              <DraggableRankDataGrid
                                providerPlaceholder={provided.placeholder}
                                tiers={tiers[tierKey as keyof typeof tiers] as PlayerRankingType[]}
                                handleDeletePlayer={(playerId) => setDeleteConfirmModal(playerId)}
                              />
                            </Grid>
                          </Box>
                        </StyledCard>
                      )}
                    </Droppable>
                  ))}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={3}>
          <DragPlayerList playerList={tiers.playerList} />
        </Grid>
      </Grid>
      {deleteConfirmModal && (
        <DeleteModal
          description='Are you sure you want to remove this player?'
          modalTitle='Delete Player'
          isLoading={deleteLoading}
          handleSubmit={() => {
            setDeleteLoading(true);
            handleDeletePlayer(deleteConfirmModal);
          }}
          handleClose={() => setDeleteConfirmModal(null)}
        />
      )}
    </DragDropContext>
  );
};

export default DragAndDropRanking;
