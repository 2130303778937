import { baseQuesryWithErrorToast } from '..';
import { createApi } from '@reduxjs/toolkit/query/react';
import {
  createFormPlayerTimelineApiUrl,
  createOrganisationPlayerApiUrl,
  createPlayerAccoladeApiUrl,
  createPlayerContractApiUrl,
  createPlayerElegibilityApiUrl,
  deleteOrganisationPlayerApiUrl,
  deletePlayerAccoladeApiUrl,
  deletePlayerContractApiUrl,
  deletePlayerElegibilityApiUrl,
  fetchCategoryContractTypeApiUrl,
  deleteTimeLineEntryApiUrl,
  fetchPlayerStateApiUrl,
  getAccoladeTypeApiUrl,
  getOrganisationPlayerListApiUrl,
  getPlayerAccoladeListApiUrl,
  getPlayerContractListApiUrl,
  getPlayerDetailsApiUrl,
  getPlayerElegibilityListApiUrl,
  getPlayerElegibilityOptionApiUrl,
  getPlayerManagerListApiUrl,
  getTimelineEntryApiUrl,
  getTimelineEntryFieldApiUrl,
  updateFormPlayerTimelineApiUrl,
  updateOrganisationPlayerApiUrl,
  updatePlayerAccoladeApiUrl,
  updatePlayerContractApiUrl,
  updatePlayerElegibilityApiUrl,
  getAgentListApiUrl,
  createAllocationListApiUrl,
  updateAllocationListApiUrl,
  deleteAllocationListApiUrl,
  fetchPlayerImportSourceApiUrl,
  fetchPlayerImportFitzroyFilesApiUrl,
  fetchPlayerImportFilePreview,
  postMergeSelectionApiUrl,
  confirmMergeApiUrl,
} from '../../../constant/ApiConstant';
import {
  reversePlayerAccoladeData,
  reversePlayerContractData,
  reversePlayerElegibilityData,
  reversePlayerManagerData,
  reverseTransformPlayerData,
  transformPlayerFileData,
  transformPlayerPreviewData,
  trasnformPlayerImportSource,
} from './util';
import {
  AccoladeAddUpdateType,
  ContractAddUpdateType,
  ElegibilityAddUpdateType,
  MergeSelectionType,
  PlayerDatabaseType,
  PlayerManagerAddUpdateType,
} from '../../../container/playerdatabase/type';

export const playerDatabaseServiceApi = createApi({
  reducerPath: 'playerDatabaseService',
  refetchOnMountOrArgChange: true,
  baseQuery: baseQuesryWithErrorToast,
  endpoints: (builder) => ({
    getPlayerDatabase: builder.query({
      query: (orgId: string) => getOrganisationPlayerListApiUrl(orgId),
    }),
    createNewPlayer: builder.mutation({
      query: ({ orgId, data }: { orgId: string; data: PlayerDatabaseType }) => ({
        url: createOrganisationPlayerApiUrl(orgId),
        method: 'POST',
        body: reverseTransformPlayerData(data),
      }),
    }),
    deletePlayer: builder.mutation({
      query: ({ orgId, playerId }: { orgId: string; playerId: string }) => ({
        url: deleteOrganisationPlayerApiUrl(orgId, playerId),
        method: 'DELETE',
      }),
    }),
    updatePlayer: builder.mutation({
      query: ({ data, orgId }: { data: PlayerDatabaseType; orgId: string }) => ({
        url: updateOrganisationPlayerApiUrl(orgId, data.id.toString()),
        method: 'PUT',
        body: reverseTransformPlayerData(data),
      }),
    }),
    getPlayerContract: builder.query({
      query: ({ orgId, playerId }: { orgId: string; playerId: string }) =>
        getPlayerContractListApiUrl(orgId, playerId),
    }),
    createNewContractPlayer: builder.mutation({
      query: ({
        orgId,
        data,
        playerId,
      }: {
        orgId: string;
        playerId: string;
        data: ContractAddUpdateType;
      }) => ({
        url: createPlayerContractApiUrl(orgId, playerId),
        method: 'POST',
        body: reversePlayerContractData(data),
      }),
    }),
    updatePlayerContract: builder.mutation({
      query: ({
        data,
        orgId,
        contractId,
      }: {
        orgId: string;
        data: ContractAddUpdateType;
        contractId: string;
      }) => ({
        url: updatePlayerContractApiUrl(orgId, contractId),
        method: 'PUT',
        body: reversePlayerContractData(data),
      }),
    }),
    deletePlayerContract: builder.mutation({
      query: ({ orgId, roasterAllocationId }: { orgId: string; roasterAllocationId: string }) => ({
        url: deletePlayerContractApiUrl(orgId, roasterAllocationId),
        method: 'DELETE',
      }),
    }),
    getPlayerAccolade: builder.query({
      query: ({ orgId, playerId }: { orgId: string; playerId: string }) =>
        getPlayerAccoladeListApiUrl(orgId, playerId),
    }),
    getAccoladeType: builder.query({
      query: () => getAccoladeTypeApiUrl(),
    }),
    createNewAccoladePlayer: builder.mutation({
      query: ({
        orgId,
        data,
        playerId,
      }: {
        orgId: string;
        playerId: string;
        data: AccoladeAddUpdateType;
      }) => ({
        url: createPlayerAccoladeApiUrl(orgId, playerId),
        method: 'POST',
        body: reversePlayerAccoladeData(data),
      }),
    }),
    updatePlayerAccolade: builder.mutation({
      query: ({
        data,
        orgId,
        accId,
      }: {
        orgId: string;
        accId: string;
        data: AccoladeAddUpdateType;
      }) => ({
        url: updatePlayerAccoladeApiUrl(orgId, accId),
        method: 'PUT',
        body: reversePlayerAccoladeData(data),
      }),
    }),
    deletePlayerAccolade: builder.mutation({
      query: ({ orgId, accId }: { orgId: string; accId: string }) => ({
        url: deletePlayerAccoladeApiUrl(orgId, accId),
        method: 'DELETE',
      }),
    }),
    getPlayerManager: builder.query({
      query: ({ orgId, playerId }: { orgId: string; playerId: string }) =>
        getPlayerManagerListApiUrl(orgId, playerId),
    }),
    getAgentList: builder.query({
      query: ({ orgId }: { orgId: string }) => getAgentListApiUrl(orgId),
    }),
    createNewAllocation: builder.mutation({
      query: ({
        orgId,
        data,
        playerId,
      }: {
        orgId: string;
        playerId: string;
        data: PlayerManagerAddUpdateType;
      }) => ({
        url: createAllocationListApiUrl(orgId),
        method: 'POST',
        body: reversePlayerManagerData(data, playerId),
      }),
    }),
    deleteAllocationList: builder.mutation({
      query: ({ orgId, allocationId }: { orgId: string; allocationId: string }) => ({
        url: deleteAllocationListApiUrl(orgId, allocationId),
        method: 'DELETE',
      }),
    }),
    updateAllocation: builder.mutation({
      query: ({
        data,
        orgId,
        playerId,
        allocationId,
      }: {
        orgId: string;
        playerId: string;
        data: PlayerManagerAddUpdateType;
        allocationId: string;
      }) => ({
        url: updateAllocationListApiUrl(orgId, allocationId),
        method: 'PUT',
        body: reversePlayerManagerData(data, playerId),
      }),
    }),
    getPlayerElegibility: builder.query({
      query: ({ orgId, playerId }: { orgId: string; playerId: string }) =>
        getPlayerElegibilityListApiUrl(orgId, playerId),
    }),
    createNewElegibility: builder.mutation({
      query: ({
        orgId,
        data,
        playerId,
      }: {
        orgId: string;
        playerId: string;
        data: ElegibilityAddUpdateType;
      }) => ({
        url: createPlayerElegibilityApiUrl(orgId, playerId),
        method: 'POST',
        body: reversePlayerElegibilityData(data),
      }),
    }),
    updatePlayerElegibility: builder.mutation({
      query: ({
        data,
        orgId,
        elegibilityId,
      }: {
        orgId: string;
        elegibilityId: string;
        data: ElegibilityAddUpdateType;
      }) => ({
        url: updatePlayerElegibilityApiUrl(orgId, elegibilityId),
        method: 'PUT',
        body: reversePlayerElegibilityData(data),
      }),
    }),
    getPlayerElegibilityOptions: builder.query({
      query: () => getPlayerElegibilityOptionApiUrl(),
    }),
    deletePlayerElegibility: builder.mutation({
      query: ({ orgId, playerElegibilityId }: { orgId: string; playerElegibilityId: string }) => ({
        url: deletePlayerElegibilityApiUrl(orgId, playerElegibilityId),
        method: 'DELETE',
      }),
    }),
    getPlayerTimeline: builder.query({
      query: ({ orgId, playerId }: { orgId: string; playerId: string }) =>
        getTimelineEntryApiUrl(orgId, playerId),
    }),
    getPlayerBio: builder.query({
      query: ({ orgId, playerId }: { orgId: string; playerId: string }) =>
        getPlayerDetailsApiUrl(orgId, playerId),
    }),
    getTimelineEntryField: builder.query({
      query: () => getTimelineEntryFieldApiUrl(),
    }),
    getCategoryContractOption: builder.query({
      query: () => fetchCategoryContractTypeApiUrl(),
    }),
    createTimelineEntry: builder.mutation({
      query: ({
        orgId,
        data,
        playerId,
        formName,
      }: {
        orgId: string;
        playerId: string;
        formName: string;
        data: any;
      }) => ({
        url: createFormPlayerTimelineApiUrl(orgId, playerId, formName),
        method: 'POST',
        body: {
          fields: data,
        },
      }),
    }),
    updateTimelineEntry: builder.mutation({
      query: ({ orgId, data, formId }: { orgId: string; formId: string; data: any }) => ({
        url: updateFormPlayerTimelineApiUrl(orgId, formId),
        method: 'PUT',
        body: {
          fields: data,
        },
      }),
    }),
    deletePlayerTimelineElegibility: builder.mutation({
      query: ({ orgId, formId }: { orgId: string; formId: string }) => ({
        url: deleteTimeLineEntryApiUrl(orgId, formId),
        method: 'DELETE',
      }),
    }),
    getPlayerState: builder.query({
      query: () => fetchPlayerStateApiUrl(),
    }),
    getPlayerImportSource: builder.query({
      query: () => fetchPlayerImportSourceApiUrl(),
      transformResponse: (response: any) => {
        return trasnformPlayerImportSource(response);
      },
    }),
    getPlayerImportFitzRoyFile: builder.query({
      query: () => fetchPlayerImportFitzroyFilesApiUrl(),
      transformResponse: (response: any) => transformPlayerFileData(response),
    }),
    getPlayerImportPreviewFile: builder.query({
      query: ({ orgId, filename }: { orgId: string; filename: string }) =>
        fetchPlayerImportFilePreview(orgId, filename),
      transformResponse: (response: any) => transformPlayerPreviewData(response),
    }),
    createMergeSelection: builder.mutation({
      query: ({
        orgId,
        fileName,
        data,
      }: {
        orgId: string;
        fileName: string;
        data: MergeSelectionType[];
      }) => ({
        url: postMergeSelectionApiUrl(orgId, fileName),
        method: 'POST',
        body: data.map((item) => ({
          row: item.row,
          player_id: item.playerId || null,
          merge_fields: item.mergeFields || [],
        })),
      }),
    }),
    confirmMergeSelection: builder.mutation({
      query: ({
        orgId,
        fileName,
        data,
      }: {
        orgId: string;
        fileName: string;
        data: MergeSelectionType[];
      }) => ({
        url: confirmMergeApiUrl(orgId, fileName),
        method: 'POST',
        body: data.map((item) => ({
          row: item.row,
          player_id: item.playerId || null,
          merge_fields: item.mergeFields || [],
        })),
      }),
    }),
  }),
});

export const {
  useCreateNewPlayerMutation,
  useGetPlayerDatabaseQuery,
  useLazyGetPlayerDatabaseQuery,
  useDeletePlayerMutation,
  useUpdatePlayerMutation,
  useGetPlayerContractQuery,
  useGetPlayerAccoladeQuery,
  useLazyGetPlayerAccoladeQuery,
  useCreateNewAccoladePlayerMutation,
  useLazyGetPlayerElegibilityQuery,
  useGetPlayerManagerQuery,
  useDeletePlayerAccoladeMutation,
  useUpdatePlayerAccoladeMutation,
  useLazyGetPlayerTimelineQuery,
  useLazyGetPlayerBioQuery,
  useGetAccoladeTypeQuery,
  useGetTimelineEntryFieldQuery,
  useCreateTimelineEntryMutation,
  useLazyGetPlayerContractQuery,
  useCreateNewContractPlayerMutation,
  useUpdatePlayerContractMutation,
  useDeletePlayerContractMutation,
  useGetPlayerElegibilityOptionsQuery,
  useCreateNewElegibilityMutation,
  useUpdatePlayerElegibilityMutation,
  useDeletePlayerElegibilityMutation,
  useGetPlayerStateQuery,
  useGetCategoryContractOptionQuery,
  useDeletePlayerTimelineElegibilityMutation,
  useUpdateTimelineEntryMutation,
  useGetAgentListQuery,
  useCreateNewAllocationMutation,
  useUpdateAllocationMutation,
  useDeleteAllocationListMutation,
  useGetPlayerImportSourceQuery,
  useLazyGetPlayerImportFitzRoyFileQuery,
  useLazyGetPlayerImportPreviewFileQuery,
  useCreateMergeSelectionMutation,
  useConfirmMergeSelectionMutation,
} = playerDatabaseServiceApi;
