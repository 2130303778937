import { draftPlayerPositionObj } from '../../../constant/OptionConstant';
import { getYears } from '../util';

export const getAgeBracket = (age: number): string => {
  if (age >= 17 && age <= 21) return '17-21';
  if (age >= 22 && age <= 25) return '22-25';
  if (age >= 26 && age <= 29) return '26-29';
  if (age >= 30) return '30+';
  return 'No Age Recorded';
};

export const getHeightBracket = (height: number): string => {
  if (height < 170) return '<170';
  if (height >= 170 && height <= 179) return '170-179';
  if (height >= 180 && height <= 189) return '180-189';
  if (height >= 190 && height <= 199) return '190-199';
  if (height >= 200) return '200+';
  return 'No Height Recorded';
};

export const getPositionColor = (position: string): string => {
  return draftPlayerPositionObj[position.toLowerCase()]?.color || '#f9f9f9';
};

export const getContractEndBracket = (endSeason?: number): string => {
  if (!endSeason) return 'Unknown';
  const years = getYears(7);
  for (let i = 0; i < years.length - 1; i++) {
    if (endSeason === Number(years[i])) return years[i];
  }
  if (endSeason >= Number(years[years.length - 1])) return `${years[years.length - 1]}+`;
  return 'Unknown';
};

export const getAvatarLetters = (firstName: string, lastName: string): string => {
  return `${firstName.charAt(0)}${lastName.charAt(0)}`;
};