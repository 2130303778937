import { Fragment, useMemo } from 'react';
import { Typography, Box } from '@mui/material';
import { PlayerDatabaseType } from '../../playerdatabase/type';
import { draftPlayerPositionObj } from '../../../constant/OptionConstant';
import { TEXT_COLOR_THEME } from '../../../theme/color';
import { DemographicFilterType } from '../type';
import { getPositionColor } from './util';
import { filterPositions, getCategoryColumns, getGridKey } from '../util';
import PlayerList from './PlayerList';

interface DemographicKanbanProps {
  playerList: PlayerDatabaseType[];
  selectedFilter: DemographicFilterType;
}

const DemographicKanbanView = ({ playerList, selectedFilter }: DemographicKanbanProps) => {
  const { viewBy, groupBy } = selectedFilter;

  const rowHeaderWidth = 150;
  const columnWidth = 250;

  const transformedData = useMemo(() => {
    if (!viewBy) return { columns: [], rows: [], playerMatrix: {} };

    const columns = getCategoryColumns(viewBy);
    const rows = groupBy && groupBy !== 'none' ? getCategoryColumns(groupBy) : [''];

    const playerMatrix: Record<string, Record<string, PlayerDatabaseType[]>> = {};
    rows.forEach(
      (row) => (playerMatrix[row] = Object.fromEntries(columns.map((col) => [col, []]))),
    );

    playerList.forEach((player) => {
      const columnKey = getGridKey(player, viewBy);
      if (!columns.includes(columnKey)) return;

      const rowKey = groupBy && groupBy !== 'none' ? getGridKey(player, groupBy) : '';
      if (!rows.includes(rowKey)) return;

      playerMatrix[rowKey][columnKey].push(player);
    });

    return filterPositions({ columns, rows, playerMatrix });
  }, [playerList, viewBy, groupBy]);

  if (!viewBy) {
    return (
      <Box p={2}>
        <Typography>Please select a "View By" option to display players.</Typography>
      </Box>
    );
  }

  return (
    <Box>
      <Box
        sx={{
          display: 'grid',
          gap: '5px',
          gridTemplateColumns:
            selectedFilter.groupBy !== 'none'
              ? `${rowHeaderWidth}px repeat(${transformedData.columns.length}, ${columnWidth}px)`
              : `repeat(${transformedData.columns.length}, ${columnWidth}px)`,
          position: 'relative',
          overflowX: 'auto',
        }}
      >
        {selectedFilter.groupBy !== 'none' && (
          <Box
            sx={{
              position: 'sticky',
              left: 0,
              zIndex: 3,
              bgcolor: '#f2e5eb',
              borderRadius: '2px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              p: 1,
              color: TEXT_COLOR_THEME.primary,
              height: '20px',
              border: `1px solid ${TEXT_COLOR_THEME.primary}`,
            }}
          >
            {groupBy !== 'none' && groupBy
              ? groupBy.charAt(0).toUpperCase() + groupBy.slice(1)
              : ''}
          </Box>
        )}
        {transformedData.columns.map((column, index) => (
          <Box
            key={`col-${index}`}
            sx={{
              border: `1px solid ${TEXT_COLOR_THEME.primary}`,
              borderRadius: '2px',
              p: 1,
              textAlign: 'center',
              bgcolor: '#f2e5eb',
              color: TEXT_COLOR_THEME.primary,
              height: '20px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {groupBy === 'position' || viewBy === 'position'
              ? draftPlayerPositionObj[column]?.text || column.toUpperCase()
              : column}
          </Box>
        ))}
        {transformedData.rows.map((row, rowIndex) => (
          <Fragment key={`row-${rowIndex}`}>
            {selectedFilter.groupBy !== 'none' && (
              <Box
                sx={{
                  position: 'sticky',
                  left: 0,
                  zIndex: 2,
                  border: '1px solid gray',
                  bgcolor: row ? getPositionColor(row) : '#f5f5f5',
                  color: '#333',
                  p: 1,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderRadius: '5px',
                  minHeight: '40px',
                  boxShadow: '2px 0 5px rgba(0,0,0,0.1)',
                }}
              >
                {viewBy === 'position'
                  ? draftPlayerPositionObj[row]?.text || row.toUpperCase()
                  : row}
              </Box>
            )}
            {transformedData.columns.map((column, colIndex) => {
              return (
                <PlayerList
                  column={column}
                  row={row}
                  key={`cell-${rowIndex}-${colIndex}`}
                  transformedData={transformedData}
                />
              );
            })}
          </Fragment>
        ))}
      </Box>
    </Box>
  );
};

export default DemographicKanbanView;