import { Box, IconButton, Typography } from '@mui/material';
import { BG_COLOR, TEXT_COLOR_THEME } from '../../../theme/color';
import { NoteAdd, Edit } from '@mui/icons-material';
import { SMART_LIST_COLUMN_WIDTH } from '../../../constant/SiteConstant';

type RowHeaderProps = {
  onAddNoteClick?: () => void;
  onEditNoteClick?: () => void;
  title: string;
  onLinkClick?: () => void;
  subText?: string;
};
const RowHeader = ({
  onAddNoteClick,
  onEditNoteClick,
  title,
  onLinkClick,
  subText,
}: RowHeaderProps) => {
  return (
    <Box
      display={'flex'}
      alignItems={'center'}
      bgcolor={BG_COLOR.white}
      px={'20px'}
      py={'2px'}
      borderRadius={'0px'}
      height={'27px'}
      width={`${SMART_LIST_COLUMN_WIDTH}px`}
      pt={'8px'}
      justifyContent={'space-between'}
    >
      <Box
        flex={2}
        justifyContent={'space-between'}
        display={'flex'}
        gap={'5px'}
        alignItems={'center'}
      >
        <Box display={'flex'} alignItems={'center'}>
          {onEditNoteClick && (
            <IconButton sx={{ padding: '0px' }}>
              <Edit
                style={{ cursor: 'pointer', color: TEXT_COLOR_THEME.primary }}
                onClick={() => onEditNoteClick()}
              />
            </IconButton>
          )}
          {onAddNoteClick && (
            <IconButton sx={{ padding: '0px' }}>
              <NoteAdd
                color={'primary'}
                style={{ cursor: 'pointer' }}
                onClick={() => onAddNoteClick()}
              />
            </IconButton>
          )}
          <Typography
            color={TEXT_COLOR_THEME.link}
            sx={{
              cursor: 'pointer',
              ':hover': {
                color: TEXT_COLOR_THEME.link,
                textDecoration: 'underline',
              },
            }}
            variant='body2'
            onClick={() => onLinkClick && onLinkClick()}
          >
            {title}
          </Typography>
        </Box>
      </Box>
      <Box flex={1}>
        <Typography variant='body2' color={TEXT_COLOR_THEME.dark}>
          {subText}
        </Typography>
      </Box>
    </Box>
  );
};

export default RowHeader;