import { OptionType } from '../../common/fields/type';

export type CurrentRosterAllocationType = {
  contractType: string;
  contractTypeDescription: string;
  endSeason: string;
  id: string;
  startSeason: string;
  teamId: string;
};

export type CurrentManagementAllocationType = {
  agentId: string;
  agentName: string;
  companyId: string;
  companyName: string;
  endDate: string;
  id: string;
  startDate: string;
};

export type PlayerDatabaseType = {
  id: string;
  organisationId: string;
  firstName: string;
  preferredFirstName: string;
  lastName: string;
  preferredLastName: string;
  juniorTeam: string;
  dateOfBirth: string;
  height: string;
  weight: string;
  position: string;
  secondaryPosition: string;
  state: string;
  preferredFoot: string;
  created: string;
  updated: string;
  currentRoasterAllocation?: CurrentRosterAllocationType;
  currentManagementAllocation?: CurrentManagementAllocationType;
};

export enum PlayerSelectedTabEnum {
  BIO = 'bio',
  CONTRACT = 'contract',
  ACCOLADE = 'accolade',
  PLAYER_MANAGER = 'playerManager',
  ELIGIBILITY = 'elegibility',
}

export type ContractType = {
  id: string;
  startDate: string;
  endDate: string;
  signingDate: string;
  team: string;
  contractType: string;
  startSeason: string;
  endSeason: string;
  signingAnnoucementSource: string;
  contractEndReason: string;
};

export type AccoladeType = {
  id: string;
  accoladeCategory: string;
  accoladeName: string;
  season: string;
};

export type AccoladeAddUpdateType = {
  season: string;
  accoladeCategory: string;
  accoladeName: string;
};

export type PlayerManagerAddUpdateType = {
  agentId: string;
  startDate: string;
  endDate: string;
};

export type ElegibilityAddUpdateType = {
  season: string;
  elegibilityType: string;
  elegiblityStatus: string;
};

export type ContractAddUpdateType = {
  startDate: string;
  endDate: string;
  signingDate: string;
  team: string;
  contractType: string;
  startSeason: string;
  endSeason: string;
  signingAnnoucementSource: string;
  contractEndReason: string;
};

type PlayerAgentType = {
  company: {
    id: string;
    name: string;
  };
  id: string;
  email: string;
  name: string;
  position: string;
};

export type PlayerManagerType = {
  agent: PlayerAgentType;
  id: string;
  endDate: string;
  startDate: string;
};

export type ElegibilityType = {
  id: string;
  season: string;
  eligibilityType: string;
  eligibilityStatus: string;
};

export type AccoladeOptionType = {
  category: OptionType[];
  name: Record<string, OptionType[]> | null;
};

export type ElegibilityOptionsType = {
  eligibilityType: OptionType[];
  eligibilityStatus: OptionType[];
};

export type OverlappingDateType = {
  startDate: string;
  endDate: string;
};

export type OverlappingSessionType = {
  startSeason: string;
  endSeason: string;
};

export type PlayerImportSourceType = {
  description: string;
  id: string;
  name: string;
  type: string;
};

export type PlayerImportFileType = {
  fileName: string;
  lastModified: string;
  size: number;
  id: number;
};

export type PlayerSummaryType = {
  exactMatches: number;
  newPlayers: number;
  potentialMatches: number;
  totalRows: number;
};

export type NewPlayerImportDataType = {
  firstName: string;
  surname: string;
  id: string;
  team: string;
  season: string;
  jumperNumber: string;
  position: string;
  providerId: string;
  dateOfBirth: string;
  draftYear: string;
  heightInCm: string;
  weightInKg: string;
  recruitedFrom: string;
  debutYear: string;
  draftType: string;
  draftPosition: string;
  dataAccessed: string;
};
export type PlayeNewPlayeDataType = {
  id: number;
  row: number;
  importData: NewPlayerImportDataType;
};

export type ExactMatchImportDataType = {
  firstName: string;
  surname: string;
  dateOfBirth: string;
  providerId: string;
  heightInCm: string;
  weightInKg: string;
  juniorTeam: string;
};

export type CurrentPlayerData = {
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  externalId: string;
  height: string;
  weight: string;
  position: string;
  juniorTeam: string;
};

export type MatchedPlayerType = {
  playerId: string;
  name: string;
  currentData: CurrentPlayerData;
};

export type ExactMatchType = {
  id: number;
  row: number;
  importData: ExactMatchImportDataType;
  match: MatchedPlayerType;
};

export type PotentialPlayerMatchesType = {
  playerId: string;
  name: string;
  matchConfidence: number;
  currentData: CurrentPlayerData;
};
export type PotentialMatchesType = {
  id: number;
  row: number;
  importData: ExactMatchImportDataType;
  matches: PotentialPlayerMatchesType[];
};
export type PlayerMatchType = {
  exactMatches: ExactMatchType[];
  potentialMatches: PotentialMatchesType[];
  newPlayers: PlayeNewPlayeDataType[];
};

export type PlayePreviewType = {
  summary: PlayerSummaryType;
  matches: PlayerMatchType;
};

export type MergeSelectionType = {
  row: number;
  playerId: string | null;
  mergeFields: string[];
};

export type MergePreviewType = {
  id: string;
  row: number;
  playerId: number | null;
  finalData: CurrentPlayerData;
};

export type MergeFieldType = {
  rowId: number;
  mergeFields: string[];
};

export type SelectedPotentialMatchesType = {
  [key: string]: {
    selectedMatch?: number;
    selectedFields: { [key: string]: boolean };
  };
};

export type PriorityChangeType = {
  rowIndex: number;
  matchIndex?: number;
  field?: string;
  type: 'match' | 'field';
};