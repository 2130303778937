import { styled, TableCell, TableContainer } from '@mui/material';
import { SMART_LIST_COLUMN_WIDTH } from '../../constant/SiteConstant';

// Styled components
export const StyledTableContainer = styled(TableContainer)(() => ({
  maxHeight: 'calc(100vh - 300px)',
  position: 'relative',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
  '-ms-overflow-style': 'none',
  scrollbarWidth: 'none',
}));

export const SimpleTableCell = styled(TableCell)(({ theme }) => ({
  padding: 0,
  border: 0,
}));

export const StickyTableCell = styled(TableCell)(({ theme }) => ({
  position: 'sticky',
  left: 0,
  backgroundColor: theme.palette.background.paper,
  zIndex: 1,
  borderBottom: 0,
  '&::after': {
    content: '""',
    position: 'absolute',
    right: 0,
    top: 0,
    bottom: 0,
    width: 4,
    boxShadow: '4px 0 8px -2px rgba(0, 0, 0, 0.1)',
  },
}));

export const StickyHeaderCell = styled(TableCell)(({ theme }) => ({
  position: 'sticky',
  top: 0,
  backgroundColor: theme.palette.grey[100],
  zIndex: 2,
  width: '81px',
}));

export const StickyHeaderAndLeftCell = styled(TableCell)(({ theme }) => ({
  position: 'sticky',
  left: 0,
  top: 0,
  backgroundColor: theme.palette.grey[100],
  zIndex: 3,
}));

export const ScrollIconCell = styled(TableCell)(
  ({ theme, position }: { theme?: any; position: 'left' | 'right' }) => ({
    position: 'sticky',
    [position]: position === 'left' ? `${SMART_LIST_COLUMN_WIDTH}px` : 0,
    top: 0,
    backgroundColor: theme.palette.grey[100],
    zIndex: 3,
    padding: theme.spacing(1),
    width: 48,
  }),
);
