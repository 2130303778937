
import { array, number, object, string } from 'yup';
import { OptionType } from '../../common/fields/type';
import { PlayerDatabaseType } from '../playerdatabase/type';
import {
  BulkValidateConflictType,
  ClubSummaryPlayerType,
  ClubSummaryType,
  ConflictType,
  ContractDetails,
  DemographicDataType,
  PlayerContractDetails,
  SMART_LIST_TAB,
  SummaryDataType,
  ValidateConflictType,
} from './type';
import { TabOptionType } from '../../common/component/type';
import { getAgeBracket, getContractEndBracket, getHeightBracket } from './demographic/util';
import { calculateAge } from '../../util/SiteHelper';
import { transformPlayerDetail } from '../playerdatabase/util';

const generateYearRange = () => {
  const currentYear = new Date().getFullYear();
  const startYear = currentYear - 15;
  const endYear = currentYear + 15;
  const yearRange = [];

  for (let year = startYear; year <= endYear; year++) {
    yearRange.push(year.toString());
  }

  return yearRange;
};
const transformContract = (data: any): ContractDetails[] => {
  if (!data) {
    return [];
  }
  return data.map(
    (item: any) =>
      ({
        contractId: item.contract_id,
        contractType: item.contract_type,
        created: item.created,
        endDate: item.end_date,
        signingDate: item.signing_date,
        startDate: item.start_date,
        updated: item.updated,
      } as ContractDetails),
  );
};
const transformPlayerList = (data: any): ClubSummaryPlayerType[] => {
  if (!data) {
    return [];
  }
  const playerTransform = data.map(
    (item: any) =>
      ({
        id: item.player.id,
        playerName: `${item.player.first_name} ${item.player.last_name}`,
        playerInfo: transformPlayerDetail(item.player),
        contractDetails: item.contractual_years.map(
          ({ contract_year, contracts }: { contract_year: number; contracts: any }) => ({
            year: contract_year,
            contract: transformContract(contracts),
          }),
        ),
      } as ClubSummaryPlayerType),
  );
  return addShowLineToPlayers(playerTransform);
};

const transformSummaryData = (data: Record<string, any>): SummaryDataType[] => {
  if (!data) {
    return [];
  }
  return Object.entries(data).map(([year, stats]) => ({
    year: Number(year),
    primaryListOfficial: stats.primary_list_official,
    primaryListProjected: stats.primary_list_projected,
    rookieListOfficial: stats.rookie_list_official,
    rookieListProjected: stats.rookie_list_projected,
    totalListOfficial: stats.total_list_official,
    totalListProjected: stats.total_list_projected,
  }));
};
export const transformSmartListPlayer = (data: any): ClubSummaryType => {
  return {
    columns: generateYearRange(),
    playerList: transformPlayerList(data?.players),
    summaryData: transformSummaryData(data?.summary),
  };
};

export const trasnformPlayerListOptions = (data: PlayerDatabaseType[]): OptionType[] => {
  if (!data) {
    return [];
  }
  return data.map(
    (playerInfo) =>
      ({
        label: `${playerInfo.firstName} ${playerInfo.lastName}`,
        value: playerInfo.id,
        display: playerInfo.currentManagementAllocation?.agentName,
      } as OptionType),
  );
};

export const addShowLineToPlayers = (players: ClubSummaryPlayerType[]) => {
  // Helper function to check if two years have the same contract ID
  const hasSameContractId = (year1: { contract: any[] }, year2: { contract: any[] }) => {
    const contractIds1 = year1.contract.map((contract: { contractId: any }) => contract.contractId);
    const contractIds2 = year2.contract.map((contract: { contractId: any }) => contract.contractId);
    return contractIds1.some((id: any) => contractIds2.includes(id));
  };

  players.forEach((player: { contractDetails: any }) => {
    const contractDetails = player.contractDetails;

    // Initialize showLine and addBorderRadius as false for all years
    contractDetails.forEach(
      (year: { showLine: boolean; addBorderRadius: boolean }, index: number) => {
        year.showLine = false;
        year.addBorderRadius = false;
      },
    );

    // Set addBorderRadius for the first and last items in the row
    if (contractDetails.length > 0) {
      contractDetails[0].addBorderRadius = true; // First item
    }

    // Loop through each year and determine if showLine should be true
    for (let i = 0; i < contractDetails.length; i++) {
      const currentYear = contractDetails[i];
      const nextYear = contractDetails[i < contractDetails.length ? i + 1 : contractDetails.length];
      if (
        i < contractDetails.length - 1 &&
        hasSameContractId(currentYear, contractDetails[i + 1])
      ) {
        // Skip setting showLine for current year if the next year has the same contract ID
        continue;
      }

      if (i > 0 && hasSameContractId(currentYear, contractDetails[i - 1])) {
        // Set showLine for the current year if the previous year has the same contract ID
        currentYear.showLine = true;
        if (nextYear) {
          nextYear.addBorderRadius = true;
        }
      } else if (currentYear.contract.length === 1) {
        // If the current year is an isolated year with a single contract
        const contractId = currentYear.contract[0].contractId;
        const isIsolated = contractDetails.every((year: { year: any; contract: any[] }) => {
          if (year.year === currentYear.year) return true;
          return !year.contract.some(
            (contract: { contractId: any }) => contract.contractId === contractId,
          );
        });

        currentYear.showLine = isIsolated;
      }
    }
  });

  return players;
};

export const getSummaryGridBgColor = (type: string) => {
  if (type === 'rookie') {
    return '#C6E0B4';
  }
  if (type === 'PROJECTED_ROOKIE') {
    return '#FFFF33';
  }
  if (type === 'PROJECTED') {
    return 'orange';
  }
  return '#70AC47';
};

export const getBorderRadiusForContract = (contractDetails: PlayerContractDetails) => {
  if (contractDetails.contract.length > 1) {
    return '5px';
  }
  if (contractDetails.showLine) {
    return '0px 5px 5px 0px';
  }
  if (contractDetails.addBorderRadius) {
    return '5px 0px 0px 5px';
  }
  return '0px';
};

export const getMarginForContract = (contractDetails: PlayerContractDetails) => {
  if (contractDetails.contract.length > 1) {
    return '5px';
  }
  if (contractDetails.showLine) {
    return '5px';
  }
  return '0px';
};

const transformConflict = (data: any): ConflictType[] => {
  if (!data) {
    return [];
  }
  return data.map(
    (item: any) =>
      ({
        existingContract: {
          contractType: item.existing_contract.contract_type,
          endSeason: item.existing_contract.end_season,
          id: item.existing_contract.id,
          startSeason: item.existing_contract.start_season,
          team: item.existing_contract.team,
          teamName: item.existing_contract.team_name,
        },
        type: item.type,
        typeDescription: item.type_description,
      } as ConflictType),
  );
};
export const transformConflictData = (data: any): ValidateConflictType | null => {
  if (!data) {
    return null;
  }
  return {
    conflicts: transformConflict(data.conflicts),
    playerId: data.player_id,
    valid: data.valid,
  };
};

export const transformBulkValidationResult = (data: any): BulkValidateConflictType | null => {
  if (!data) {
    return null;
  }
  return {
    valid: data.valid,
    validationResults: data.validation_results.map((item: any) => transformConflictData(item)),
  };
};

// Schema for each item in the playerInfo array
const playerInfoSchema = object().shape({
  playerName: string().required('Player Name is required'),
  playerId: number().required('Player ID is required'),
  startSeason: string().required('Start Season is required'),
  endSeason: string()
    .required('End Season is required')
    .test(
      'is-greater',
      'End Season must be greater than or equal to Start Season',
      function (value) {
        const { startSeason } = this.parent;
        return !startSeason || !value || getYear(value) >= getYear(startSeason);
      },
    ),
  contractType: string().required('Contract Type is required'),
});

// Helper function to extract the year from a date string
const getYear = (dateString: string) => {
  return new Date(dateString).getFullYear();
};

export const validationBulAddSchema = object().shape({
  selectedTeam: string().required('Team is required'),
  startSeason: string().required('Start Season is required'),
  endSeason: string()
    .required('End Season is required')
    .test(
      'is-greater',
      'End Season must be greater than or equal to Start Season',
      function (value) {
        const { startSeason } = this.parent;
        return !startSeason || !value || getYear(value) >= getYear(startSeason);
      },
    ),
  contractType: string().required('Contract Type is required'),
  playerInfo: array()
    .of(playerInfoSchema) // Validate each item in the array using playerInfoSchema
    .min(1, 'At least one player must be added') // Ensure at least 1 player is present
    .test('playerInfo-validation', 'Invalid player info', function (value) {
      // If playerInfo is empty, it's invalid (already handled by .min(1))
      if (!value || value.length === 0) return false;

      // Validate each item in playerInfo
      return value.every((item, index) => {
        const { startSeason, endSeason } = item;
        if (!startSeason || !endSeason || getYear(endSeason) < getYear(startSeason)) {
          // Add error to the specific player's field
          return this.createError({
            path: `playerInfo[${index}].endSeason`,
            message: 'End Season must be greater than or equal to Start Season',
          });
        }
        return true;
      });
    }),
});

export const smartListTabOption: TabOptionType[] = [
  {
    label: 'Contracts',
    value: SMART_LIST_TAB.CONTRACTS,
  },
  {
    label: 'Demographics',
    value: SMART_LIST_TAB.DEMOGRAPHICS,
  },
  {
    label: 'Afl Contract Years',
    value: SMART_LIST_TAB.AFL_CONTRACT_YEARS,
  },
];

export const getYears = (numberOfYear: number) => {
  const currentYear = new Date().getFullYear();
  const years = [];
  for (let i = 0; i < numberOfYear; i++) {
    years.push(`${currentYear + i}${i === numberOfYear - 1 ? '+' : ''}`);
  }
  return years;
};
export const AGE_COLUMNS = ['17-21', '22-25', '26-29', '30+', 'No Age Recorded'];
export const HEIGHT_COLUMNS = [
  '<170',
  '170-179',
  '180-189',
  '190-199',
  '200+',
  'No Height Recorded',
];

const positionMap = {
  DEF: ['gendef', 'def', 'keydef'],
  MID: ['mid', 'midfwd', 'wing'],
  FWD: ['genfwd', 'fwd', 'keyfwd'],
  RUCK: ['ruck'],
};
export const POSITION_COLUMNS = ['DEF', 'MID', 'FWD', 'RUCK', 'No Position Recorded'];
export const CONTRACT_END_COLUMNS = getYears(7);

export const getGridKey = (player: PlayerDatabaseType, category: string) => {
  switch (category) {
    case 'age':
      return getAgeBracket(calculateAge(player.dateOfBirth));
    case 'height':
      return getHeightBracket(Number(player.height));
    case 'position':
      const pos = Object.entries(positionMap).find(([key, value]) =>
        value.includes(player.position.toLowerCase()),
      );
      return pos ? pos[0] : 'No Position Recorded';
    case 'contractEnd':
      return getContractEndBracket(Number(player.currentRoasterAllocation?.endSeason));
    default:
      return '';
  }
};

export const getCategoryColumns = (category: string) => {
  const categoryMap: Record<string, string[]> = {
    age: AGE_COLUMNS,
    height: HEIGHT_COLUMNS,
    position: POSITION_COLUMNS,
    contractEnd: CONTRACT_END_COLUMNS,
  };
  return categoryMap[category] || [];
};

export function filterPositions(data: DemographicDataType) {
  let { columns, rows, playerMatrix } = data;

  const excludedCategories = ['No Position Recorded', 'No Height Recorded', 'No Age Recorded'];

  // Check which categories are completely empty in playerMatrix
  const emptyCategories = excludedCategories.filter((category) =>
    Object.values(playerMatrix).every(
      (positions) => !positions[category] || positions[category].length === 0,
    ),
  );

  // Remove empty categories from columns
  columns = columns.filter((col) => !emptyCategories.includes(col));

  // Remove empty categories from rows (except if the only row is an empty string)
  if (rows.length > 1 || rows[0] !== '') {
    rows = rows.filter((row) => !emptyCategories.includes(row));
  }

  // Remove empty categories from playerMatrix properly
  playerMatrix = Object.fromEntries(
    Object.entries(playerMatrix).map(([key, value]) => {
      // Create a new object excluding empty categories
      const filteredValue = Object.fromEntries(
        Object.entries(value).filter(([posKey]) => !emptyCategories.includes(posKey)),
      );

      return [key, filteredValue];
    }),
  );

  return { columns, rows, playerMatrix };
}
