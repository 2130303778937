import { Box, Button, Typography } from '@mui/material';
import { OptionType } from '../fields/type';
import { BUTTON_COLOR_THEME } from '../../theme/color';

type CustomButtonTabProps = {
  options: OptionType[];
  selectedValue: string;
  label?: string;
  handleButtonClick: (value: string) => void;
};
const CustomButtonTab = ({
  options,
  label,
  selectedValue,
  handleButtonClick,
}: CustomButtonTabProps) => {
  return (
    <Box>
      {label && (
        <Typography mb={'3px'} fontWeight={'bold'}>
          {label}
        </Typography>
      )}
      <Box display={'flex'} gap={'10px'}>
        {options.map((item) => (
          <Button
            key={item.value}
            fullWidth
            sx={{
              height: '40px',
              fontSize: 12,
              background: selectedValue === item.value ? BUTTON_COLOR_THEME.primary : undefined,
              borderColor: selectedValue !== item.value ? BUTTON_COLOR_THEME.primary : undefined,
              color: selectedValue !== item.value ? BUTTON_COLOR_THEME.primary : undefined,
              '&:hover': {
                background: selectedValue === item.value ? BUTTON_COLOR_THEME.primary : undefined,
                borderColor: selectedValue !== item.value ? BUTTON_COLOR_THEME.primary : undefined,
                color: selectedValue !== item.value ? BUTTON_COLOR_THEME.primary : undefined,
              },
            }}
            onClick={() => handleButtonClick(item.value)}
            variant={selectedValue === item.value ? 'contained' : 'outlined'}
          >
            {item.label}
          </Button>
        ))}
      </Box>
    </Box>
  );
};

export default CustomButtonTab;
