import { Box, Grid, IconButton, MenuItem, Select } from '@mui/material';
import { usePlayerList, usePlayerRanking } from './hook';
import { FaCog } from 'react-icons/fa';
import RankingListModal from './component/RankingListModal';
import { RankingListType } from './type';
import DragAndDropRanking from './component/DragAndDropRanking';
import { differenceBy } from 'lodash';

const PlayerRanking = () => {
  const {
    selectedPlayerRanking,
    setSelectedPlayerRanking,
    openRankingListModal,
    setOpenRankingLisModal,
    refreshList,
    rankingList,
    playerRankingListLoading,
    refreshListKey,
    selectedRankingData,
    updatePlayerPos,
    handleDeletePlayer,
  } = usePlayerRanking();
  const { playerList } = usePlayerList();

  return (
    <Grid container>
      <Grid item xs={12}>
        <Box width={'30%'} display={'flex'} gap={'20px'}>
          <Box width={'100%'}>
            <Select
              size='small'
              fullWidth
              value={selectedPlayerRanking}
              onChange={(e) => setSelectedPlayerRanking(e.target.value)}
            >
              {rankingList?.map((item: RankingListType) => (
                <MenuItem value={item.id} key={item.id}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </Box>
          <Box>
            <IconButton onClick={() => setOpenRankingLisModal(true)}>
              <FaCog />
            </IconButton>
          </Box>
        </Box>
        {openRankingListModal && (
          <RankingListModal
            refreshList={refreshList}
            handleClose={() => setOpenRankingLisModal(false)}
            rankingList={rankingList}
          />
        )}
      </Grid>
      {selectedPlayerRanking && (
        <Grid item xs={12} mt={'10px'}>
          <DragAndDropRanking
            key={refreshListKey}
            playerList={differenceBy(playerList, selectedRankingData?.ranks || [], 'id')}
            rank={selectedRankingData?.ranks || []}
            updatePlayerPos={updatePlayerPos}
            isLoading={playerRankingListLoading}
            handleDeletePlayer={handleDeletePlayer}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default PlayerRanking;
