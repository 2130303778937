import { Grid, Typography } from '@mui/material';
import CustomButtonTab from '../../common/component/CustomButtonTab';
import {
  DemographicGroupViewByOption,
  DemographicViewByOption,
} from '../../constant/OptionConstant';
import { useDemographic } from './hook';
import { PlayerDatabaseType } from '../playerdatabase/type';
import DemographicKanbanView from './demographic/DemographicKanbanView';

type DemographicProps = {
  playerList: PlayerDatabaseType[];
};
const Demographic = ({ playerList }: DemographicProps) => {
  const { selectedFilter, setSelectedFilter } = useDemographic();
  return (
    <Grid container>
      <Grid item xs={12} mt={'5px'}>
        <Grid container columnSpacing={'10px'} justifyContent={'space-between'}>
          <Grid item xs={6} mb={'3px'}>
            <Grid container columnSpacing={'20px'}>
              <Grid item xs={12}>
                <Typography mb={'3px'} fontWeight={'bold'}>
                  View By
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12} borderRight={'1px gray solid'} paddingRight={'10px'}>
              <CustomButtonTab
                options={DemographicViewByOption}
                label=''
                handleButtonClick={(value) => {
                  setSelectedFilter((prev) => ({
                    ...prev,
                    viewBy: prev.viewBy === value ? '' : value,
                  }));
                }}
                selectedValue={selectedFilter.viewBy}
              />
            </Grid>
          </Grid>
          <Grid item xs={6} mb={'3px'}>
            <Grid container columnSpacing={'20px'}>
              <Grid item xs={12}>
                <Typography mb={'3px'} fontWeight={'bold'}>
                  Group By
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12} paddingRight={'10px'}>
              <CustomButtonTab
                options={DemographicGroupViewByOption}
                label=''
                handleButtonClick={(value) => {
                  setSelectedFilter((prev) => ({
                    ...prev,
                    groupBy: value,
                  }));
                }}
                selectedValue={selectedFilter.groupBy}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} mt={'5px'}>
        <DemographicKanbanView playerList={playerList} selectedFilter={selectedFilter} />
      </Grid>
    </Grid>
  );
};

export default Demographic;