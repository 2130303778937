import { Box, Paper, Typography } from '@mui/material';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis,
} from 'recharts';
import { TeamFlagOptions } from '../../../constant/OptionConstant';
import { TEXT_COLOR_THEME } from '../../../theme/color';
import { memo, useMemo } from 'react';
import { TeamData } from './type';

interface ContractYearsChartProps {
  teams: TeamData[];
  showNonProjected: boolean;
  showDetailedView?: boolean;
}

const CustomTooltip = ({ active, payload, label }: TooltipProps<number, string>) => {
  if (!active || !payload || payload.length === 0) return null;

  return (
    <Paper
      sx={{
        p: 2,
        backgroundColor: 'rgba(255, 255, 255, 0.95)',
        borderRadius: 2,
        boxShadow: '0 8px 24px rgba(0,0,0,0.15)',
        backdropFilter: 'blur(10px)',
        border: '1px solid rgba(0,0,0,0.1)',
        minWidth: 200,
      }}
    >
      <Typography
        variant='h6'
        sx={{
          mb: 2,
          color: 'text.primary',
          fontWeight: 600,
          borderBottom: '1px solid rgba(0,0,0,0.1)',
          pb: 1,
        }}
      >
        {label}
      </Typography>
      {payload.map((entry, index) => (
        <Box
          key={index}
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            mb: 1,
            '&:last-child': { mb: 0 },
          }}
        >
          <Typography
            variant='body2'
            sx={{
              color: entry.color,
              fontWeight: 500,
              mr: 2,
            }}
          >
            {entry.name}
          </Typography>
          <Typography
            variant='body2'
            sx={{
              fontWeight: 600,
              color: 'text.primary',
            }}
          >
            {entry.value}
          </Typography>
        </Box>
      ))}
    </Paper>
  );
};

const ContractYearsChart = ({
  teams,
  showNonProjected,
  showDetailedView = false,
}: ContractYearsChartProps) => {
  const chartData = useMemo(
    () =>
      teams.map((team) => {
        const baseData = {
          teamId:
            TeamFlagOptions.find((item) => item.id === team.teamId)?.teamName ||
            `Team ${team.teamId}`,
          total: showNonProjected ? team.totalNonProjectedYears : team.totalContractYears,
        };

        if (showDetailedView) {
          return {
            ...baseData,
            ...(showNonProjected ? team.yearlyBreakdownNonProjected : team.yearlyBreakdown),
          };
        }

        return baseData;
      }),
    [teams, showNonProjected, showDetailedView],
  );

  const allYears: string[] = useMemo(
    () =>
      Array.from(
        new Set(
          teams.flatMap((team) =>
            Object.keys(showNonProjected ? team.yearlyBreakdownNonProjected : team.yearlyBreakdown),
          ),
        ),
      ).sort(),
    [teams, showNonProjected],
  );

  return (
    <Box
      sx={{
        height: 400,
        width: '100%',
        borderRadius: 2,
        boxShadow: 1,
      }}
    >
      <ResponsiveContainer>
        <BarChart
          data={chartData}
          barGap={5}
          barCategoryGap={30}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray='3 3' stroke='rgba(0,0,0,0.1)' />
          <XAxis dataKey='teamId' axisLine={{ stroke: '#666' }} />
          <YAxis axisLine={{ stroke: '#666' }} tickLine={{ stroke: '#666' }} />
          <Tooltip content={<CustomTooltip />} />
          <Legend verticalAlign='top' height={36} />

          {showDetailedView ? (
            allYears.map((year) => (
              <Bar
                key={year}
                dataKey={year}
                stackId='a'
                fill={`hsl(330, 100%, ${1 + (parseInt(year) - Number(allYears[0]) || 0) * 5}%)`}
                name={year}
              />
            ))
          ) : (
            <Bar
              dataKey='total'
              fill={TEXT_COLOR_THEME.primary}
              name={showNonProjected ? 'Non-Projected Years' : 'Total Years'}
            />
          )}
        </BarChart>
      </ResponsiveContainer>
    </Box>
  );
};

export default memo(ContractYearsChart);