import { useState } from 'react';
import { DemographicDataType } from '../type';
import { Box, Button, Divider, Tooltip, Typography } from '@mui/material';
import { Header2, Header3, Tag } from '../../../common/component/Text';
import { TEXT_COLOR_INFO_TYPE } from '../../../theme/color';
import { calculateAge } from '../../../util/SiteHelper';
import { draftPlayerPositionObj } from '../../../constant/OptionConstant';
import { getPositionColor } from './util';

type PlayerListProps = {
  transformedData: DemographicDataType;
  row: string;
  column: string;
};
const PlayerList = ({ transformedData, column, row }: PlayerListProps) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const playersInCell = transformedData.playerMatrix[row][column];

  return (
    <Box
      sx={{
        p: 1,
        minHeight: '40px',
        overflow: 'auto',
        bgcolor: '#fafafa',
        borderRadius: '2px',
        boxShadow: '0 2px 4px rgba(0,0,0,0.05)',
        '&:hover': {
          boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
        },
      }}
    >
      {playersInCell.length === 0 ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '20px',
          }}
        >
          <Typography variant='body2' color='text.secondary' fontStyle='italic'>
            No players
          </Typography>
        </Box>
      ) : (
        <>
          {(isExpanded ? playersInCell : playersInCell.slice(0, 2)).map((player, playerIndex) => (
            <Box key={`player-${playerIndex}`} mb={1}>
              {playerIndex > 0 && <Divider sx={{ my: 1 }} />}
              <Box gap={'5px'} sx={{ display: 'flex', alignItems: 'center' }}>
                <Box width={'20%'}>
                  <Tag textAlign={'center'} width={'20px'} bgcolor={TEXT_COLOR_INFO_TYPE.neutral}>
                    {calculateAge(player.dateOfBirth)}
                  </Tag>
                </Box>
                <Box width={'70%'}>
                  <Header2 textsize='12px' style={{ lineHeight: '10px' }} fontweight={700}>
                    {player.firstName} {player.lastName}
                  </Header2>

                  <Header3
                    style={{
                      fontWeight: 500,
                      lineHeight: 'normal',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      display: '-webkit-box',
                      WebkitLineClamp: '1',
                      WebkitBoxOrient: 'vertical',
                    }}
                    textsize={'12px'}
                  >
                    {player.height}cm • {player.currentRoasterAllocation?.endSeason}
                  </Header3>
                </Box>
                <Box width={'20%'}>
                  <Tooltip
                    placement='right-start'
                    title={
                      draftPlayerPositionObj[player.position]?.text || player.position.toUpperCase()
                    }
                  >
                    <Tag
                      textAlign={'center'}
                      width={'20px'}
                      textsize='12px'
                      height={'fit-content'}
                      bgcolor={getPositionColor(player.position)}
                    >
                      {draftPlayerPositionObj[player.position]?.text ||
                        player.position[0].toUpperCase()}
                    </Tag>
                  </Tooltip>
                </Box>
              </Box>
            </Box>
          ))}
          {playersInCell.length > 2 && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                mt: 1,
              }}
            >
              <Button
                variant='text'
                size='small'
                fullWidth
                onClick={() => setIsExpanded((prev) => !prev)}
                sx={{
                  textTransform: 'none',
                  fontSize: '12px',
                }}
              >
                {isExpanded ? 'Show Less' : `Show +${playersInCell.length - 2} Players`}
              </Button>
            </Box>
          )}
        </>
      )}
    </Box>
  );
};

export default PlayerList;